/* eslint-disable no-debugger */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
} from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from 'recharts';
import GraphqlService from '../../../service/graphqlService';
import { useFeedbackTools } from '../../Utils/FeedbackTools';
import { useStyles } from '../../../config/styles';
// import mockupServiceHealth from '../../../mockup/services_health.json';

function roundPercentage(percentage) {
  return Math.round(parseInt(percentage, 10) * 100) / 100;
}

function getValue(percentage) {
  if (percentage) { return roundPercentage(percentage); }
  return 0;
}
// eslint-disable-next-line no-unused-vars
function getRealSeverity(severity) {
  if (severity === 'POOR') { return 'POOR'; }
  if (severity === 'IMPROVABLE') { return 'IMPROVABLE'; }
  if (severity === 'GOOD') { return 'GOOD'; }
  return 'GOOD';
}

export default function HealthServiceList() {
  const classes = useStyles();
  const [servicesHealth, setServicesHealth] = useState([]);
  const match = useRouteMatch();
  const history = useHistory();
  const [overallLoader, setOverallLoader] = useState(false);
  // DATA RETRIEVAL_______________________
  const { showError } = useFeedbackTools();

  async function fetchServicesHealth() {
    try {
      const servicesHealthResponse = await GraphqlService.getServicesHealth();
      // const servicesHealthResponse = mockupServiceHealth;
      if (servicesHealthResponse.getServicesHealth.list.length > 0) {
        servicesHealthResponse.getServicesHealth.list.forEach(
          // eslint-disable-next-line no-param-reassign
          (o) => { o.value = getValue(o.healthPercentage); },
        );
        setServicesHealth(servicesHealthResponse.getServicesHealth.list);
      }
      setOverallLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${JSON.stringify(error, null, 4)}`);
    }
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchServicesHealth();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <h1>
        IoT Services Health
      </h1>
      <Paper
        elevation={2}
        style={{
          padding: 16,
        }}
      >
        <h2>Overall</h2>
        {overallLoader ? servicesHealth.length > 0 ? (
          <div>
            <Box m={6} />
            <Grid container>
              <Grid item align="center" text-align="center" xs={12}>
                <RadarChart
                  cx={600}
                  cy={125}
                  outerRadius={100}
                  width={1200}
                  height={250}
                  data={servicesHealth}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey="service" />
                  <PolarRadiusAxis />
                  <Radar
                    name="Services Health"
                    dataKey="value"
                    stroke="#4051b5"
                    fill="#4051b5"
                    fillOpacity={0.6}
                  />
                </RadarChart>
              </Grid>
            </Grid>
            <Box m={10} />
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell}>Service</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status updated at</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">View service details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {servicesHealth.map((row) => (
                    <TableRow key={row.service}>
                      <TableCell component="th" scope="row">
                        {row.service}
                      </TableCell>
                      <TableCell align="right">
                        {(row.healthPercentage)
                          ? `${getRealSeverity(row.health)} (${roundPercentage(row.healthPercentage)}%)` : getRealSeverity(row.health)}
                      </TableCell>
                      <TableCell align="right">{row.healthUpdatedAt}</TableCell>
                      <TableCell align="right">
                        <Button
                          component={Button}
                          variant="contained"
                          color="primary"
                          onClick={() => history.push(`${match.url}/${row.service}`)}
                        >
                          Service details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
          : (
            <div>
              <p>No data.</p>
            </div>
          )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
      </Paper>
    </div>
  );
}
