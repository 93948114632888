/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import * as React from 'react';
import { useEffect, useState } from 'react';

import RefreshIcon from '@material-ui/icons/Refresh';

import GraphqlService from '../../service/graphqlService';

const columns = [
  { field: 'file', headerName: 'Type', width: 100 },
  { field: 'id', headerName: 'Key', width: 450 },
  { field: 'lastModified', headerName: 'Last Modified', width: 200 },
  { field: 'eTag', headerName: 'eTag', width: 130 },
  { field: 'size', headerName: 'Size', width: 90 },
  { field: 'storageClass', headerName: 'Storage Class', width: 130 },
];

export default function BinaryList() {
  const [binaries, setBinaries] = useState([]);

  // DATA RETRIEVAL_______________________

  async function fetchBinaries() {
    const binariesList = await GraphqlService.getBinaries();
    const tempBinaries = [];
    if (binariesList.commonPrefixes) {
      binariesList.commonPrefixes.forEach((o) => {
        o.id = o.prefix;
        o.file = 'Folder';
        tempBinaries.push(o);
      });
    }
    if (binariesList.contents) {
      binariesList.contents.forEach((o) => {
        o.id = o.key;
        o.file = 'File';
        tempBinaries.push(o);
      });
    }
    setBinaries(tempBinaries);
  }
  // WEBPAGE______________________________

  useEffect(() => {
    fetchBinaries();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<RefreshIcon />}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={fetchBinaries}
        >
          Refresh
        </Button>
      </div>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={binaries}
          columns={columns}
          pageSize={10}
          checkboxSelection
        />
      </div>
    </div>
  );
}
