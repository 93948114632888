import React from 'react';
import { PropTypes } from 'prop-types';
import { useConfirmationDialog, ConfirmationDialog } from './ConfirmationDialog';
import { useErrorSnackbar, ErrorSnackbar } from './ErrorSnackbar';
import { useInfoDialog, InfoDialog } from './InfoDialog';

const FeedbackContext = React.createContext();

const FeedbackProvider = (props) => {
  FeedbackProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  const { children } = props;
  const confirmationDialog = useConfirmationDialog();
  const errorSnackbar = useErrorSnackbar();
  const infoDialog = useInfoDialog();

  const context = {
    showError: errorSnackbar.showError,
    askConfirmation: confirmationDialog.askConfirmation,
    viewInfoDialog: infoDialog.viewInfoDialog,
  };

  return (
    <FeedbackContext.Provider
      value={context}
    >
      {children}
      {confirmationDialog.isOpen && (
        <ConfirmationDialog
          options={confirmationDialog.options}
          onClose={confirmationDialog.onClose}
        />
      )}
      {infoDialog.isOpen && (
        <InfoDialog
          options={infoDialog.options}
          onClose={infoDialog.onClose}
        />
      )}
      {errorSnackbar.errorMsg && (
        <ErrorSnackbar
          error={errorSnackbar.errorMsg}
          onClose={errorSnackbar.handleClose}
        />
      )}
    </FeedbackContext.Provider>
  );
};

const useFeedbackTools = () => React.useContext(FeedbackContext);

export { FeedbackProvider, useFeedbackTools };
