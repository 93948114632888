import { API } from 'aws-amplify';
import {
  createAsset, createBinary, createDeployment, deleteAsset, deleteBinary,
  deleteDeployment, getBinary, listAssets, listBinaries, listDeployments,
  getAssetTypes, getHealth, getOverallHealth, getServicesHealth, getServiceHealth,
  getHealthHistory, getBrandsHealth, getBrandHealth, getFailedTests, getTestDetails,
  getServiceHealthHistory, getBrandHealthHistory,
} from '../config/graphql';
import S3Service from './s3Service';

export default class GraphqlService {
  static async getAssets() {
    const postData = await API.graphql({
      query: listAssets,
    });
    return postData.data.listAssets.list;
  }

  static async getDeployments() {
    const postData = await API.graphql({
      query: listDeployments,
    });
    return postData.data.listDeployments.list;
  }

  static async getBinaries() {
    const postData = await API.graphql({
      query: listBinaries,
    });
    return postData.data.listBinaries;
  }

  static async getBinary(filekey) {
    const postData = await API.graphql({
      query: getBinary,
      variables: {
        filekey,
      },
    });
    return postData.data.getBinary.key;
  }

  static async getAssetTypes() {
    const postData = await API.graphql({
      query: getAssetTypes,
    });
    return postData.data.getAssetTypes.assetTypes;
  }

  static async createAsset(type, chunks, region, version, timestampToApply) {
    const postData = await API.graphql({
      query: createAsset,
      variables: {
        type, chunks, region, version, timestampToApply,
      },
    });
    return postData.data.createAsset;
  }

  static async createDeployment(fileID, target) {
    const postData = await API.graphql({
      query: createDeployment,
      variables: {
        target,
        fileID,
      },
    });
    return postData.data.createDeployment.deploymentID;
  }

  static async createBinary(file) {
    try {
      const filekey = await S3Service.uploadFile(file);
      const postData = await API.graphql({
        query: createBinary,
        variables: {
          filekey,
        },
      });
      return postData.data.createBinary.key;
    } catch (err) {
      console.error(err.stack || err);
      return '';
    }
  }

  static async deleteAsset(fileID) {
    const postData = await API.graphql({
      query: deleteAsset,
      variables: {
        fileID,
      },
    });
    return postData.data.deleteAsset.response;
  }

  static async deleteDeployment(deploymentID) {
    const postData = await API.graphql({
      query: deleteDeployment,
      variables: {
        deploymentID,
      },
    });
    return postData.data.deleteDeployment.response;
  }

  static async deleteBinary(filekey) {
    const postData = await API.graphql({
      query: deleteBinary,
      variables: {
        filekey,
      },
    });
    return postData.data.deleteBinary.key;
  }

  static async getHealth() {
    const postData = await API.graphql({
      query: getHealth,
    });
    return postData.data;
  }

  static async getOverallHealth() {
    const postData = await API.graphql({
      query: getOverallHealth,
    });
    return postData.data;
  }

  static async getServicesHealth() {
    const postData = await API.graphql({
      query: getServicesHealth,
    });
    return postData.data;
  }

  static async getServiceHealth(serviceId) {
    const postData = await API.graphql({
      query: getServiceHealth,
      variables: {
        serviceId,
      },
    });
    return postData.data;
  }

  static async getHealthHistory() {
    const postData = await API.graphql({
      query: getHealthHistory,
    });
    return postData.data;
  }

  static async getBrandsHealth() {
    const postData = await API.graphql({
      query: getBrandsHealth,
    });
    return postData.data;
  }

  static async getBrandHealth(brandId) {
    const postData = await API.graphql({
      query: getBrandHealth,
      variables: {
        brandId,
      },
    });
    return postData.data;
  }

  static async getFailedTests(testId) {
    const postData = await API.graphql({
      query: getFailedTests,
      variables: {
        testId,
      },
    });
    return postData.data;
  }

  static async getServiceHealthHistory(serviceId) {
    const postData = await API.graphql({
      query: getServiceHealthHistory,
      variables: {
        serviceId,
      },
    });
    return postData.data;
  }

  static async getTestDetails(filePath) {
    const postData = await API.graphql({
      query: getTestDetails,
      variables: {
        filePath,
      },
    });
    return postData.data;
  }

  static async getBrandHealthHistory(brandId) {
    const postData = await API.graphql({
      query: getBrandHealthHistory,
      variables: {
        brandId,
      },
    });
    return postData.data;
  }
}
