import { Storage } from 'aws-amplify';

export default class S3Service {
  static async uploadFile(file) {
    try {
      const response = await Storage.put(file.name, file);
      return response.key;
    } catch (err) {
      console.error(err.stack || err);
      return false;
    }
  }

  static async downloadFile(filename) {
    try {
      const response = await Storage.get(filename, { download: true });
      return response.body;
    } catch (err) {
      console.error(err.stack || err);
      return false;
    }
  }

  static async deleteFile(filename) {
    try {
      const response = await Storage.remove(filename);
      return response;
    } catch (err) {
      console.error(err.stack || err);
      return false;
    }
  }
}
