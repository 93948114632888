export const UserPoolClientId = 'tiv3sng7fjn489b327gvdbk68';
export const UserPoolId = 'eu-west-1_cJojvSgwX';
export const IdentityPoolId = 'eu-west-1:c4c2a9cf-dc4b-4e98-bf6f-c797f5a14f4d';
export const GraphQLAPIURL = 'https://pcbugomor5d65mmvgqy7tkud24.appsync-api.eu-west-1.amazonaws.com/graphql';
export const target = 'PRO';
export const iotDevBucketName = 'assetdeploy-dev-updatess3bucket-oz2ydono21ek';
export const iotStagBucketName = 'assetdeploy-stag-updatess3bucket-1m0j44dwx5cpl';

const config = {
  aws_project_region: 'eu-west-1',
  aws_user_pools_id: UserPoolId,
  aws_user_pools_web_client_id: UserPoolClientId,
  aws_appsync_graphqlEndpoint: GraphQLAPIURL,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    identityPoolId: IdentityPoolId,
    region: 'eu-west-1',
    userPoolId: UserPoolId,
    userPoolWebClientId: UserPoolClientId,
  },
  Storage: {
    AWSS3: {
      bucket: `alm-graphql-files-bucket-${target.toLowerCase()}`,
      region: 'eu-west-1',
    },
  },
};

export default config;
