/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RouterIcon from '@material-ui/icons/Router';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import HealthIcon from '@material-ui/icons/LocalHospital';
import LoveIcon from '@material-ui/icons/Favorite';
import SpeedIcon from '@material-ui/icons/Speed';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import {
  BrowserRouter as Router, Switch, Route, Link,
} from 'react-router-dom';
import Header from './Header';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function LeftBar() {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {/*          {['Binaries'].map((text, index) => (
            <ListItem button component={Link} key={text} to="/binaries">
              <ListItemIcon>
                <AttachFileIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          <ListItem button component={Link} to="/assets" key="Assets">
            <ListItemIcon>
              <FileCopyIcon />
            </ListItemIcon>
            <ListItemText primary="Assets_" />
          </ListItem>
          <ListItem button component={Link} to="/deployments" key="Deployments">
            <ListItemIcon>
              <SystemUpdateAltIcon />
            </ListItemIcon>
            <ListItemText primary="Deployments" />
          </ListItem>
 */}
          {' '}
          <ListItem button component={Link} to="/health/platform" key="HealthPlatform">
            <ListItemIcon>
              <SpeedIcon />
            </ListItemIcon>
            <ListItemText primary="Platform Health" />
          </ListItem>
          <ListItem button component={Link} to="/health/services" key="HealthServices">
            <ListItemIcon>
              <HealthIcon />
            </ListItemIcon>
            <ListItemText primary="Services Health" />
          </ListItem>
          <ListItem button component={Link} to="/health/brands" key="HealthMachineModels">
            <ListItemIcon>
              <RouterIcon />
            </ListItemIcon>
            <ListItemText primary="Machine Models Health" />
          </ListItem>

          <ListItem href="https://forms.office.com/r/Gf9jjgcQVZ" key="Feedback">
            <ListItemIcon>
              <LoveIcon />
            </ListItemIcon>
            <a href="https://forms.office.com/r/Gf9jjgcQVZ">Give us Feedback :)</a>
          </ListItem>

          {/* {['Devices'].map((text, index) => (
            <ListItem button key={text} disabled>
              <ListItemIcon>
                <RouterIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['Machines'].map((text, index) => (
            <ListItem button key={text} disabled>
              <ListItemIcon>
                <EmojiFoodBeverageIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))} */}
        </List>
      </div>
    </Drawer>
  );
}
