import AssetList from '../components/Asset/List';
import AssetCreate from '../components/Asset/Create';
import DeploymentList from '../components/Deployment/List';
import DeploymentCreate from '../components/Deployment/Create';
import DeploymentDisplay from '../components/Deployment/Display';
import Binary from '../screens/Binary';
import AssetEdit from '../components/Asset/Edit';
import AssetDisplay from '../components/Asset/Display';
import HealthServiceDisplay from '../components/Health/Service/Display';
import HealthPlatformDisplay from '../components/Health/Platform/Display';
import HealthServiceList from '../components/Health/Service/List';
import HealthBrandList from '../components/Health/Brand/List';
import HealthBrandDisplay from '../components/Health/Brand/Display';

export default [ // Must go from specific to general
  {
    path: '/assets/create', name: 'Create new asset', component: AssetCreate,
  },
  {
    path: '/assets/edit/:assetId', name: 'Edit asset', component: AssetEdit,
  },
  {
    path: '/assets/:assetId/edit', name: 'Edit asset', component: AssetEdit,
  },
  {
    path: '/assets/:assetId', name: 'Display asset', component: AssetDisplay,
  },
  {
    path: '/assets', name: 'Assets', component: AssetList,
  },
  {
    path: '/deployments/create', name: 'Create new deployment', component: DeploymentCreate,
  },
  {
    path: '/deployments/:deploymentId', name: 'Display deployment', component: DeploymentDisplay,
  },
  {
    path: '/deployments', name: 'Deployments', component: DeploymentList,
  },
  {
    path: '/binaries', name: 'Binaries', component: Binary,
  },
  {
    path: '/health/services/:serviceId', name: 'Display health of service', component: HealthServiceDisplay,
  },
  {
    path: '/health/services', name: 'Services Health', component: HealthServiceList,
  },
  {
    path: '/health/platform', name: 'Platform Health', component: HealthPlatformDisplay,
  },
  {
    path: '/health/brands/:brandId', name: 'Display health of brand', component: HealthBrandDisplay,
  },
  {
    path: '/health/brands', name: 'Brands Health', component: HealthBrandList,
  },
];
