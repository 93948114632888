/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button,
} from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  YAxis,
} from 'recharts';
import HeatMap from 'react-heatmap-grid';
import GraphqlService from '../../../service/graphqlService';
import { useStyles } from '../../../config/styles';
import { useFeedbackTools } from '../../Utils/FeedbackTools';
import mockupBrandsHealth from '../../../mockup/machine_models_health.json';

const green = '#58C0A1';
const yellow = '#E2dd53';
const blue = '#5597DE';
const orange = '#F2B35B';
const red = '#F16D64';

function roundPercentage(percentage) {
  return Math.round(parseInt(percentage, 10) * 100) / 100;
}

function getValue(percentage) {
  if (percentage) { return roundPercentage(percentage); }
  return 0;
}

function getBrandName(brandId) {
  if (brandId === '1') { return 'Majesto'; }
  if (brandId === '2') { return 'VTP2'; }
  if (brandId === '3') { return 'iMug'; }
  if (brandId === '5') { return 'Momento'; }
  if (brandId === '6') { return 'Barista'; }
  if (brandId === '7') { return 'Figaro'; }
  if (brandId === '9') { return 'Genio'; }
  if (brandId === '10') { return 'Venus NN'; }
  if (brandId === '11') { return 'Blue'; }
  if (brandId === '13') { return 'Maya'; }
  if (brandId === '14') { return 'Milo'; }
  if (brandId === '17') { return 'Apollo'; }
  if (brandId === '18') { return 'Lockbox'; }
  if (brandId === '19') { return 'Fuji'; }
  if (brandId === '22') { return 'Venus NDG'; }
  if (brandId === '23') { return 'Arbuckle'; }
  if (brandId === '24') { return 'Cooper-i'; }
  if (brandId === '26') { return 'Canary Board'; }
  if (brandId === '28') { return 'Venus NN Family'; }
  return 'Not registered?';
}
// eslint-disable-next-line no-unused-vars
function getRealSeverity(severity) {
  if (severity === 'POOR') { return 'POOR'; }
  if (severity === 'IMPROVABLE') { return 'IMPROVABLE'; }
  if (severity === 'GOOD') { return 'GOOD'; }
  return 'NA';
}

export default function HealthBrandList() {
  const classes = useStyles();
  const [brandsHealth, setBrandsHealth] = useState([]);
  const match = useRouteMatch();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [brandsHealthHeatMap, setBrandsHealthHeatMap] = useState([]);
  const [heatMapxLabels, setxLabels] = useState([]);
  const [heatMapyLabels, seyLabels] = useState([]);
  const [heatMapData, setheatMapData] = useState([]);
  const [heatMapDataObject, setheatMapDataObject] = useState([]);
  const [overallLoader, setOverallLoader] = useState(false);

  // DATA RETRIEVAL_______________________
  const { showError } = useFeedbackTools();

  async function fetchBrandsHealth() {
    try {
      const brandsHealthResponse = await GraphqlService.getBrandsHealth();
      // const brandsHealthResponse = mockupBrandsHealth;
      if (brandsHealthResponse.getBrandHealth.list.length > 0) {
        // eslint-disable-next-line no-param-reassign
        brandsHealthResponse.getBrandHealth.list.forEach((o) => { o.value = getValue(o.Overall.healthPercentage); });
        // eslint-disable-next-line no-param-reassign
        brandsHealthResponse.getBrandHealth.list.forEach((o) => { o.MachineName = getBrandName(o.Brand); });
        // eslint-disable-next-line no-shadow
        const brandsHealthHeatMap = [];
        const xLabels = [];
        const yLabels = [];
        const data = [];

        brandsHealthResponse.getBrandHealth.list.forEach((o, index) => {
          if (o.Service.list.length > 0) {
            brandsHealthHeatMap[index] = {};
            brandsHealthHeatMap[index].name = getBrandName(o.Brand);
            brandsHealthHeatMap[index].data = [];
            const data2 = [];
            yLabels.push(getBrandName(o.Brand));
            o.Service.list.forEach((a, index2) => {
              brandsHealthHeatMap[index].data[index2] = {};
              brandsHealthHeatMap[index].data[index2].y = getValue(a.healthPercentage);
              brandsHealthHeatMap[index].data[index2].x = a.service;
              if (index === 0) xLabels.push(a.service);

              data2.push(a.health);
            });
            data.push(data2);
          }
        });
        const heatMapDataObject1 = {};
        heatMapDataObject1.x = xLabels;
        heatMapDataObject1.y = yLabels;
        heatMapDataObject1.data = data;
        setheatMapData(data);
        seyLabels(yLabels);
        setxLabels(xLabels);
        setheatMapDataObject(heatMapDataObject1);

        setBrandsHealthHeatMap(brandsHealthHeatMap);
        setBrandsHealth(brandsHealthResponse.getBrandHealth.list);
      }
      setOverallLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${JSON.stringify(error, null, 4)}`);
    }
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchBrandsHealth();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <h1>
        IoT Machine Models Health
      </h1>
      <Paper
        elevation={2}
        style={{
          padding: 16,
        }}
      >
        <h2>Overall</h2>
        {overallLoader ? brandsHealth.length > 0 ? (
          <div>
            <Grid container>
              <Grid item align="center" text-align="center" xs={12}>
                <RadarChart
                  cx={600}
                  cy={125}
                  outerRadius={100}
                  width={1200}
                  height={250}
                  data={brandsHealth}
                >
                  <PolarGrid />
                  <PolarAngleAxis dataKey="MachineName" />
                  <PolarRadiusAxis domain={[0, 100]} />
                  <Radar
                    name="Services Health"
                    dataKey="value"
                    stroke="#4051b5"
                    fill="#4051b5"
                    fillOpacity={0.6}
                  />
                </RadarChart>
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell}>Machine Model - ID</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Machine Name</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status updated at</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Date</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">E2E tests updated at</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">See details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brandsHealth.map((row) => (
                    <TableRow key={row.Brand}>
                      <TableCell component="th" scope="row">
                        {row.Brand}
                      </TableCell>
                      <TableCell align="right">{row.MachineName}</TableCell>
                      <TableCell align="right">
                        {(row.Overall.healthPercentage)
                          ? `${getRealSeverity(row.Overall.health)} (${roundPercentage(row.Overall.healthPercentage)}%)` : getRealSeverity(row.Overall.health)}
                      </TableCell>
                      <TableCell align="right">{row.Overall.healthUpdatedAt}</TableCell>
                      <TableCell align="right">{row.Overall.date}</TableCell>
                      <TableCell align="right">{row.Overall.e2eTestsUpdatedAt}</TableCell>
                      <TableCell align="right">
                        <Button
                          component={Button}
                          variant="contained"
                          color="primary"
                          onClick={() => history.push(`${match.url}/${row.Brand}`)}
                        >
                          Machine Model details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box m={6} />
            <Divider />
            <h2>Health of Machine Model per Service</h2>
            {heatMapData.length > 0 ? (
              <div
                width={1200}
                height={200}
                align="center"
                text-align="center"
                fontSize="6px"
                fontWeight="100"
                margin="5px"
                background={green}
              >
                <HeatMap
                  xLabels={heatMapxLabels}
                  yLabels={heatMapyLabels}
                  data={heatMapData}
                  xLabelsLocation={'top'}
                  xLabelWidth={100}
                  background={'#00ff00'}
                  cellStyle={(background, value, min, max, data, x, y) => ({
                    background:
                      value === 'GOOD'
                        ? '#58C0A1'
                        : value === 70
                          ? '#5597DE'
                          : value === 'IMPROVABLE'
                            ? '#E2dd53'
                            : '#F16D64',

                    fontSize: '0.5rem',
                    width: '100px',
                  })}
                  cellRender={(value) => value && ''}
                />
              </div>
            )
              : (
                <div>
                  <p>No data.</p>
                </div>
              )}
          </div>
        )
          : (
            <div>
              <p>No data.</p>
            </div>
          )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
      </Paper>
    </div>
  );
}
