/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useEffect,
} from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

import GraphqlService from '../../service/graphqlService';

export default function AssetDisplay() {
  // STATE________________________________
  const { assetId } = useParams();
  const match = useRouteMatch();
  const history = useHistory();
  const defaultValues = {
    type: '',
    region: '',
    version: '',
    timestampToApply: '',
    binary: '',
  };
  const {
    reset, control,
  } = useForm({ defaultValues });

  // DATA RETRIEVAL_______________________

  async function fetchAssets() {
    const assetsList = await GraphqlService.getAssets();
    const asset = assetsList.filter((filteredAsset) => filteredAsset.fileID === assetId)[0];
    if (asset) {
      reset({
        type: asset.type,
        region: asset.region,
        version: asset.metaData?.version ? asset.metaData.version : 0,
        timestampToApply: asset.metaData?.timestampToApply ? asset.metaData.timestampToApply : '99:99',
        binary: asset.chunks[0],
      });
    }
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <div>
      <h1>
        Display asset
      </h1>
      <form>

        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <TextField
              {...field}
              label="Type"
              required
              style={{ width: '90%' }}
              disabled
            />
          )}
        />

        <Controller
          control={control}
          name="version"
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label="Version"
              required
              style={{ width: '90%' }}
              disabled
            />
          )}
        />
        <br />

        <Controller
          control={control}
          name="region"
          render={({ field }) => (
            <TextField
              {...field}
              label="Region"
              required
              style={{ width: '90%' }}
              disabled
            />
          )}
        />
        <br />

        <Controller
          control={control}
          name="timestampToApply"
          render={({ field }) => (
            <TextField
              {...field}
              label="Timestamp To Apply"
              required
              style={{ width: '90%' }}
              disabled
            />
          )}
        />
        <br />

        <Controller
          control={control}
          name="binary"
          render={({ field }) => (
            <TextField
              {...field}
              label="Binary"
              required
              style={{ width: '90%' }}
              disabled
            />
          )}
        />
        <br />

        <br />
        <br />

        <Button
          variant="contained"
          endIcon={<EditIcon />}
          color="primary"
          onClick={() => { history.push(`${match.url}/edit`); }}
        >
          Edit
        </Button>
        <Button
          onClick={() => history.goBack()}
          variant="contained"
          color="default"
        >
          Back
        </Button>
      </form>
    </div>
  );
}
