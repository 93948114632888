import { useState, useCallback } from 'react';
import { InfoDialog } from './InfoDialog';

const defaultOpts = {
  title: 'Title',
  content: 'Content',
};

const useInfoDialog = () => {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState(defaultOpts);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const viewInfoDialog = useCallback((opts) => {
    setOptions(Object.assign(defaultOpts, { ...opts }));
    setOpen(true);
  }, []);

  return {
    isOpen,
    options,
    onClose,
    viewInfoDialog,
  };
};

export { useInfoDialog, InfoDialog };
