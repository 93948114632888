/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import EditIcon from '@material-ui/icons/Edit';
// eslint-disable-next-line no-unused-vars
import AddIcon from '@material-ui/icons/Add';
import GraphqlService from '../../service/graphqlService';

const columns = [
  { field: 'id', headerName: 'ID', width: 550 },
  { field: 'type', headerName: 'Type', width: 180 },
  { field: 'dateTime', headerName: 'DateTime', width: 200 },
  { field: 'region', headerName: 'Region', width: 130 },
  { field: 'chunks', headerName: 'Chunks', width: 430 },
  { field: 'content', headerName: 'Content', width: 120 },
  { field: 'hashList', headerName: 'HashList', width: 130 },
  { field: 'hash', headerName: 'Hash', width: 130 },
  { field: 'manufacturerSignature', headerName: 'ManufacturerSignature', width: 130 },
  { field: 'softwareRequirements', headerName: 'SoftwareRequirements', width: 130 },
  { field: 'hardwareRequirements', headerName: 'HardwareRequirements', width: 130 },
];

export default function AssetList() {
  const [assets, setAssets] = useState([]);
  const [selection, setSelection] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();

  // DATA RETRIEVAL_______________________

  async function fetchAssets() {
    const assetsList = await GraphqlService.getAssets();
    // eslint-disable-next-line no-param-reassign
    assetsList.forEach((o) => { o.id = o.fileID; });
    setAssets(assetsList);
  }

  async function deleteOneAssetAction(assetId) {
    setSubmitting(true);
    await GraphqlService.deleteAsset(assetId);
    fetchAssets();
    setSubmitting(false);
  }

  async function deleteAssetButtonAction() {
    selection.forEach((s) => {
      deleteOneAssetAction(s);
    });
    setSelection([]);
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchAssets();
    setSelection([]);
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <h1>
        Assets
      </h1>
      <div style={{ width: '100%' }}>
        <div>
          <Button
            component={Button}
            variant="contained"
            color="primary"
            endIcon={<AddIcon />}
            onClick={() => history.push(`${match.url}/create`)}
          >
            Create new asset
          </Button>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<FindInPageIcon />}
            disabled={selection.length < 1 || selection.length > 1 || submitting}
            onClick={() => history.push(`${match.url}/${selection[0]}`)}
          >
            See details
          </Button>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<EditIcon />}
            disabled={selection.length < 1 || selection.length > 1 || submitting}
            onClick={() => history.push(`${match.url}/${selection[0]}/edit`)}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<DeleteIcon />}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={deleteAssetButtonAction}
            disabled={selection.length < 1 || submitting}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<RefreshIcon />}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={fetchAssets}
          >
            Refresh
          </Button>
        </div>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={assets}
            columns={columns}
            pageSize={10}
            checkboxSelection
            onSelectionChange={(newSelection) => {
              setSelection(newSelection.rowIds);
            }}
          />
        </div>
      </div>
    </div>
  );
}
