/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Hub, Auth } from 'aws-amplify';
import Header from './Header';
import LeftBar from './LeftBar';
import routes from '../config/routes';
import CustomBreadcrumbs from './CustomBreadcrumbs';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Routes() {
  return routes.map(({ path: routespath, component: Component }) => (
    <Route
      exact
      path={routespath}
      key={routespath}
      render={(props) => {
        const crumbs = routes
          // Get all routes that contain the current one.
          .filter(({ path: filterpath }) => props?.match.path.includes(filterpath))
          // Swap out any dynamic routes with their param values.
          .map(({ path: mappath, name: mapname, ...rest }) => ({
            name: Object.keys(props?.match.params).length
              ? Object.keys(props?.match.params).map(
                (param) => (
                  mappath.endsWith(`:${param}`)
                    ? props?.match?.params[param]
                    : mapname),
              )[0]
              : mapname,
            path: Object.keys(props?.match.params).length
              ? Object.keys(props?.match.params).reduce(
                (path, param) => path.replace(
                  `:${param}`, props?.match.params[param],
                ), mappath,
              )
              : mappath,
            ...rest,
          }))
          .reverse();
        return (
          <div className="p-8">
            <CustomBreadcrumbs crumbs={crumbs} />
            <Component />
          </div>
        );
      }}
    />
  ));
}

export default function Layout() {
  const classes = useStyles();
  const [user, setUser] = useState(null);

  async function checkUser() {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      setUser(authUser);
    } catch (error) {
      setUser(null);
    }
  }

  useEffect(() => {
    const updateUser = async (data) => {
      // console.log(data.payload.event);
      if (data.payload.event === 'signIn') {
        checkUser();
      }
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    checkUser();
    return () => Hub.remove('auth', updateUser); // cleanup
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header user={user} setUser={setUser} />
      <LeftBar />
      <main className={classes.content}>
        {/* <Toolbar /> */}
        {user ? (
          <Switch>
            {Routes()}
            <Route
              exact
              path="/"
              render={() => <Redirect to="/health/platform" />}
            />
          </Switch>
        )
          : (
            null
          )}
      </main>
    </div>
  );
}
