/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
// eslint-disable-next-line no-unused-vars
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // eslint-disable-next-line no-unused-vars
  Legend,
  Cell,
} from 'recharts';
// eslint-disable-next-line import/no-duplicates
import {
  // eslint-disable-next-line no-unused-vars
  WiDaySunny, WiRain, WiStormShowers, WiDayFog, WiMeteor,
} from 'react-icons/wi';
import { IconContext } from 'react-icons';
import GraphqlService from '../../../service/graphqlService';
import { useStyles } from '../../../config/styles';
import { useFeedbackTools } from '../../Utils/FeedbackTools';
import {
  red,
  green,
  yellow,
  blue,
} from '../../Utils/Legend';

/* function getStatusFromValue(value) {
  if (value === 'POOR') { return 100; }
  if (value === 'IMPROVABLE') { return 70; }
  if (value === 'GOOD') { return 50; }
  return 50;
} */
// eslint-disable-next-line no-unused-vars
function getRealSeverity(severity) {
  if (severity === 'POOR') { return 'POOR'; }
  if (severity === 'IMPROVABLE') { return 'IMPROVABLE'; }
  if (severity === 'GOOD') { return 'GOOD'; }
  return 'NA';
}
function getColor(severity) {
  if (severity === 'POOR') { return red; }
  if (severity === 'IMPROVABLE') { return yellow; }
  if (severity === 'GOOD') { return green; }
  return green;
}
function getIcon(severity) {
  if (severity === 'POOR') { return 'WiMeteor'; }
  if (severity === 'IMPROVABLE') { return 'WiDayFog'; }
  if (severity === 'GOOD') { return 'WiDaySunny'; }
  return 'WiDaySunny';
}
/* function getValue(severity) {
  if (severity === 'POOR') { return 100; }
  if (severity === 'IMPROVABLE') { return 70; }
  if (severity === 'GOOD') { return 50; }
  return 50;
} */

function roundPercentage(percentage) {
  return Math.round(parseInt(percentage, 10) * 100) / 100;
}

export default function HealthPlatformDisplay() {
  const classes = useStyles();
  const [health, setHealth] = useState('');
  const [healthPercentage, setHealthPercentage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [e2eTestsUpdatedAt, setE2eTestsUpdatedAt] = useState('');
  const [healthUpdatedAt, setHealthUpdatedAt] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [lastNonGoodHealthAt, setLastNonGoodHealthAt] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [lastNonGoodHealthStatus, setLastNonGoodHealthStatus] = useState('');
  const [healthHistory, setHealthHistory] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [color, setColor] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [icon, setIcon] = useState('');
  const [leyend, setLeyend] = useState('');

  const [overallLoader, setOverallLoader] = useState(false);
  const [historyLoader, setHistoryLoader] = useState(false);

  // DATA RETRIEVAL_______________________
  const { showError } = useFeedbackTools();

  async function fetchHealth() {
    try {
      const overallHealthResponse = await GraphqlService.getOverallHealth();
      // eslint-disable-next-line max-len
      overallHealthResponse.getOverallHealth.color = getColor(overallHealthResponse.getOverallHealth.health);
      // eslint-disable-next-line max-len
      overallHealthResponse.getOverallHealth.icon = getIcon(overallHealthResponse.getOverallHealth.health);
      // eslint-disable-next-line max-len
      setColor(overallHealthResponse.getOverallHealth.color);
      setIcon(overallHealthResponse.getOverallHealth.icon);
      setLeyend(overallHealthResponse.getOverallHealth.leyend);
      setHealth(overallHealthResponse.getOverallHealth.health);
      setHealthPercentage(overallHealthResponse.getOverallHealth.healthPercentage);
      setDate(overallHealthResponse.getOverallHealth.date);
      setE2eTestsUpdatedAt(overallHealthResponse.getOverallHealth.e2eTestsUpdatedAt);
      setHealthUpdatedAt(overallHealthResponse.getOverallHealth.healthUpdatedAt);
      setLastNonGoodHealthAt(overallHealthResponse.getOverallHealth.lastNonGoodHealth.healthUpdatedAt);
      setLastNonGoodHealthStatus(overallHealthResponse.getOverallHealth.lastNonGoodHealth.health);
      setOverallLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${JSON.stringify(error, null, 4)}`);
    }
  }

  async function fetchHealthHistory() {
    try {
      const healthHistoryResponse = await GraphqlService.getHealthHistory();
      if (healthHistoryResponse.getHealthHistory.list.length > 0) {
        // eslint-disable-next-line no-param-reassign
        healthHistoryResponse.getHealthHistory.list.forEach((o) => { o.value = roundPercentage(o.healthPercentage); });
        setHealthHistory(healthHistoryResponse.getHealthHistory.list);
      }
      setHistoryLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${JSON.stringify(error, null, 4)}`);
    }
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchHealth();
    fetchHealthHistory();
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#b8b4b8', padding: 5, color: '#FFFFFF', textAlign: 'center',
          }}
        >
          <p className="label"><b>{`${label}`}</b></p>
          <p className="intro"><b>{roundPercentage(payload[0].value)}</b></p>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <h1>
        IoT Platform Health
      </h1>
      <Paper
        elevation={2}
        style={{
          padding: 16,
        }}
      >
        <h2>Overall</h2>
        {overallLoader ? (
          <div
            width={1200}
            height={400}
            align="center"
            text-align="center"
            className={classes.weatherReport}
          >
            <Grid container spacing={4}>
              <Grid item xs={4}>
                {(function () {
                  if (health === 'POOR') {
                    return (
                      <IconContext.Provider value={{ color: blue, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiStormShowers /></div>
                      </IconContext.Provider>

                    );
                  }
                  if (health === 'IMPROVABLE') {
                    return (
                      <IconContext.Provider value={{ color: yellow, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiDayFog /></div>
                      </IconContext.Provider>
                    );
                  }
                  if (health === 'GOOD') {
                    return (
                      <IconContext.Provider value={{ color: green, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiDaySunny /></div>
                      </IconContext.Provider>
                    );
                  }

                  return (
                    <IconContext.Provider value={{ color: green, className: 'global-class-name', size: '10em' }}>
                      {' '}
                      <div><WiDaySunny /></div>
                    </IconContext.Provider>
                  );
                }())}
              </Grid>
              <Grid item xs={4}>
                <Box fontSize={40}>
                  {(healthPercentage) ? `${getRealSeverity(health)} (${roundPercentage(healthPercentage)}%)` : getRealSeverity(health)}
                </Box>
                <Box>
                  {leyend}
                </Box>
              </Grid>
              <Grid item align="left" text-align="left" xs={4}>
                <Box>
                  Updated at:
                  {' '}
                  {healthUpdatedAt}
                </Box>
                <Box>
                  Last Failure Health Status/Date:
                  {' '}
                  {lastNonGoodHealthStatus}
                  /
                  {lastNonGoodHealthAt}
                </Box>
                <Box>
                  E2E tests ran at:
                  {' '}
                  {e2eTestsUpdatedAt}
                </Box>
              </Grid>
            </Grid>
          </div>
        )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
        <Divider />
        <h2>History</h2>
        <Box m={6} />
        {historyLoader ? healthHistory.length > 0 ? (
          <div>
            <Grid container spacing={4}>
              <Grid item align="center" text-align="center" xs={12}>
                <BarChart
                  width={1200}
                  height={100}
                  data={healthHistory}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis domain={[1, 100]} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="value" barSize={60} label={{ position: 'top' }}>
                    {healthHistory.map((entry) => (
                      <Cell fill={getColor(entry.health)} key={`entry-${new Date().getTime()}`} />
                    ))}
                  </Bar>
                </BarChart>
              </Grid>
            </Grid>
            <Box m={10} />
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell} align="left">Date</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">E2E Tests Updated At</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status Updated At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {healthHistory.map((row) => (
                    <TableRow key={row.date}>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell align="right">
                        {(row.healthPercentage)
                          ? `${getRealSeverity(row.health)} (${roundPercentage(row.healthPercentage)}%)` : getRealSeverity(row.health)}
                      </TableCell>
                      <TableCell align="right">{row.e2eTestsUpdatedAt}</TableCell>
                      <TableCell align="right">{row.healthUpdatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
          : (
            <div>
              <p>No data.</p>
            </div>
          )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
      </Paper>
    </div>
  );
}
