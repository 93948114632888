/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useEffect,
} from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import GraphqlService from '../../service/graphqlService';

export default function AssetDisplay() {
  // STATE________________________________
  const { deploymentId } = useParams();
  const history = useHistory();
  const defaultValues = {
    target: '',
    fileID: '',
  };
  const {
    reset, control,
  } = useForm({ defaultValues });

  // DATA RETRIEVAL_______________________

  async function fetchDeployments() {
    const deploymentsList = await GraphqlService.getDeployments();
    deploymentsList.forEach((o) => {
      // eslint-disable-next-line no-param-reassign
      o.id = o.deploymentID;
      // Deployments sometimes do not bring deployID, we need this field for the colums data
      //    eslint-disable-next-line no-param-reassign
      if (o.file) o.fileID = o.file.fileID;
    });
    const deployment = deploymentsList
      .filter((filteredDeployment) => filteredDeployment.deploymentID === deploymentId)[0];
    if (deployment) {
      reset({
        fileID: deployment.fileID,
        target: deployment.target,
      });
    }
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchDeployments();
  }, []);

  return (
    <div>
      <h1>
        Display deployment
      </h1>
      <form>

        <Controller
          control={control}
          name="fileID"
          render={({ field }) => (
            <TextField
              {...field}
              label="FileID"
              required
              style={{ width: '90%' }}
              disabled
            />
          )}
        />

        <Controller
          control={control}
          name="target"
          render={({ field }) => (
            <TextField
              {...field}
              label="Target"
              required
              style={{ width: '90%' }}
              disabled
            />
          )}
        />
        <br />

        <br />
        <br />

        <Button
          onClick={() => history.goBack()}
          variant="contained"
          color="default"
        >
          Back
        </Button>
      </form>
    </div>
  );
}
