/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  // eslint-disable-next-line no-unused-vars
  WiDaySunny, WiStormShowers, WiDayFog, WiMeteor,
} from 'react-icons/wi';
import { IconContext } from 'react-icons';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
} from 'recharts';
import GraphqlService from '../../../service/graphqlService';
import { useStyles } from '../../../config/styles';
import { useFeedbackTools } from '../../Utils/FeedbackTools';

const green = '#58C0A1';
const yellow = '#E2dd53';
const red = '#F16D64';
const blue = '#5597DE';

function getBrandName(brandId) {
  if (brandId === '1') { return 'Majesto'; }
  if (brandId === '2') { return 'VTP2'; }
  if (brandId === '3') { return 'iMug'; }
  if (brandId === '5') { return 'Momento'; }
  if (brandId === '6') { return 'Barista'; }
  if (brandId === '7') { return 'Figaro'; }
  if (brandId === '9') { return 'Genio'; }
  if (brandId === '10') { return 'Venus NN'; }
  if (brandId === '11') { return 'Blue'; }
  if (brandId === '13') { return 'Maya'; }
  if (brandId === '14') { return 'Milo'; }
  if (brandId === '17') { return 'Apollo'; }
  if (brandId === '18') { return 'Lockbox'; }
  if (brandId === '19') { return 'Fuji'; }
  if (brandId === '22') { return 'Venus NDG'; }
  if (brandId === '23') { return 'Arbuckle'; }
  if (brandId === '24') { return 'Cooper-i'; }
  if (brandId === '26') { return 'Canary Board'; }
  if (brandId === '28') { return 'Venus NN Family'; }
  return 'Not registered?';
}

// eslint-disable-next-line no-unused-vars
function getValue(severity) {
  if (severity === 'POOR') { return 100; }
  if (severity === 'IMPROVABLE') { return 70; }
  if (severity === 'GOOD') { return 50; }
  return 50;
}

// eslint-disable-next-line no-unused-vars
function getRealSeverity(severity) {
  if (severity === 'POOR') { return 'POOR'; }
  if (severity === 'IMPROVABLE') { return 'IMPROVABLE'; }
  if (severity === 'GOOD') { return 'GOOD'; }
  return 'NA';
}

// eslint-disable-next-line no-unused-vars
function getColor(severity) {
  if (severity === 'POOR') { return red; }
  if (severity === 'IMPROVABLE') { return yellow; }
  if (severity === 'GOOD') { return green; }
  return green;
}
// eslint-disable-next-line no-unused-vars
function getStatusFromValue(value) {
  if (value === 100) { return 'POOR'; }
  if (value === 70) { return 'IMPROVABLE'; }
  if (value === 50) { return 'GOOD'; }
  return 'GOOD';
}

function roundPercentage(percentage) {
  return Math.round(parseInt(percentage, 10) * 100) / 100;
}

export default function HealthBrandDisplay() {
  const classes = useStyles();
  // STATE________________________________
  const { brandId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [brandHealth, setBrandHealth] = useState([]);
  const [servicesHealth, setServicesHealth] = useState([]);
  const [machineName, setMachineName] = useState('');
  const [health, setHealth] = useState('');
  const [healthPercentage, setHealthPercentage] = useState('');
  const [healthUpdatedAt, setHealthUpdatedAt] = useState('');
  const [brandHealthHistory, setBrandHealthHistory] = useState([]);

  const [overallLoader, setOverallLoader] = useState(false);
  const [historyLoader, setHistoryLoader] = useState(false);

  // DATA RETRIEVAL_______________________
  const { showError } = useFeedbackTools();

  async function fetchBrandHealth() {
    try {
      const brandHealthResponse = await GraphqlService.getBrandHealth(brandId);
      if (brandHealthResponse.getBrandHealth.list.length > 0) {
        setMachineName(getBrandName(brandId));
        setHealth(brandHealthResponse.getBrandHealth.list[0].Overall.health);
        setHealthPercentage(brandHealthResponse.getBrandHealth.list[0].Overall.healthPercentage);
        setHealthUpdatedAt(brandHealthResponse.getBrandHealth.list[0].Overall.healthUpdatedAt);
        setBrandHealth(brandHealthResponse.getBrandHealth.list);
        if (brandHealthResponse.getBrandHealth.list[0]) {
          setServicesHealth(brandHealthResponse.getBrandHealth.list[0].Service.list);
        }
      }
      setOverallLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${error.message}`);
    }
  }
  async function fetchBrandHealthHistory() {
    try {
      const brandHealthHistoryResponse = await GraphqlService.getBrandHealthHistory(brandId);
      if (brandHealthHistoryResponse.getPlatformHealthHistory.list.length > 0) {
        // eslint-disable-next-line no-param-reassign
        brandHealthHistoryResponse.getPlatformHealthHistory.list[0].PlatformHealthHistory.list.forEach((o) => { o.value = roundPercentage(o.healthPercentage); });
        setBrandHealthHistory(brandHealthHistoryResponse.getPlatformHealthHistory.list[0].PlatformHealthHistory.list);
      }
      setHistoryLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${JSON.stringify(error, null, 4)}`);
    }
  }
  // WEBPAGE______________________________

  useEffect(() => {
    fetchBrandHealth();
    fetchBrandHealthHistory();
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#b8b4b8', padding: 5, color: '#FFFFFF', textAlign: 'center',
          }}
        >
          <p className="label"><b>{`${label}`}</b></p>
          <p className="intro"><b>{roundPercentage(payload[0].value)}</b></p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <h1>
        {machineName}
        {' '}
        Brand Health
      </h1>
      <Paper
        elevation={2}
        style={{
          padding: 16,
        }}
      >
        <h2>Overall</h2>
        {overallLoader ? (
          <div
            width={1200}
            height={400}
            align="center"
            text-align="center"
            className={classes.weatherReport}
          >
            <Grid container>
              <Grid item xs={4}>
                {(function () {
                  if (health === 'POOR') {
                    return (
                      <IconContext.Provider value={{ color: blue, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiStormShowers /></div>
                      </IconContext.Provider>

                    );
                  }
                  if (health === 'IMPROVABLE') {
                    return (
                      <IconContext.Provider value={{ color: yellow, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiDayFog /></div>
                      </IconContext.Provider>
                    );
                  }
                  if (health === 'GOOD') {
                    return (
                      <IconContext.Provider value={{ color: green, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiDaySunny /></div>
                      </IconContext.Provider>
                    );
                  }

                  return (
                    <IconContext.Provider value={{ color: green, className: 'global-class-name', size: '10em' }}>
                      {' '}
                      <div><WiDaySunny /></div>
                    </IconContext.Provider>
                  );
                }())}
              </Grid>
              <Grid item xs={4}>
                <Box fontSize={40}>
                  {(healthPercentage) ? `${getRealSeverity(health)} (${roundPercentage(healthPercentage)}%)` : getRealSeverity(health)}
                </Box>
              </Grid>
              <Grid item align="left" text-align="left" xs={4}>
                <Box>
                  Updated at:
                  {' '}
                  {healthUpdatedAt}
                </Box>
              </Grid>
            </Grid>
          </div>
        )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
        <Box m={6} />
        <Divider />
        <h2>
          {machineName}
          {' '}
          Health History
          {' '}
        </h2>
        {historyLoader ? brandHealthHistory.length > 0 ? (
          <div>
            <Box m={6} />
            <Grid container>
              <Grid item align="center" text-align="center" xs={12}>
                <BarChart
                  width={1200}
                  height={100}
                  data={brandHealthHistory}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis domain={[0, 100]} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="value" barSize={60} label={{ position: 'top' }}>
                    {brandHealthHistory.map((entry) => (
                      <Cell fill={getColor(entry.health)} />
                    ))}
                  </Bar>
                </BarChart>
              </Grid>
            </Grid>
            <Box m={10} />
            <Box m={6} />
            <Divider />
            <h2>
              Current Health of Services for
              {' '}
              {machineName}
            </h2>
            {servicesHealth.length > 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableHeadCell}>Service</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Health Status</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Health Status updated at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicesHealth.map((row) => (
                      <TableRow key={row.service}>
                        <TableCell component="th" scope="row">
                          {row.service}
                        </TableCell>
                        <TableCell align="right">
                          {(row.healthPercentage) ? `${getRealSeverity(row.health)} (${roundPercentage(row.healthPercentage)}%)` : getRealSeverity(row.health)}
                        </TableCell>
                        <TableCell align="right">{row.healthUpdatedAt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
              : (
                <div>
                  <p>No data.</p>
                </div>
              )}
            <Box m={6} />
            <h2>
              {machineName}
              {' '}
              Health History Details
            </h2>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeadCell} align="right">Date</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">E2E Tests Updated At</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status</TableCell>
                    <TableCell className={classes.tableHeadCell} align="right">Health Status Updated At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brandHealthHistory.map((row) => (
                    <TableRow key={row.date}>
                      <TableCell align="right">{row.date}</TableCell>
                      <TableCell align="right">{row.e2eTestsUpdatedAt}</TableCell>
                      <TableCell align="right">
                        {(row.healthPercentage) ? `${getRealSeverity(row.health)} (${roundPercentage(row.healthPercentage)}%)` : getRealSeverity(row.health)}
                      </TableCell>
                      <TableCell align="right">{row.healthUpdatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
          </div>
        )
          : (
            <div>
              <p>No data.</p>
            </div>
          )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
      </Paper>
    </div>
  );
}
