import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { PropTypes } from 'prop-types';

const ErrorSnackbar = (props) => {
  ErrorSnackbar.propTypes = {
    error: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };
  const { error, onClose } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={6000}
    >
      <Alert
        variant="filled"
        severity="error"
        onClose={handleClose}
      >
        <AlertTitle>ERROR</AlertTitle>
        {error}
      </Alert>
    </Snackbar>
  );
};

export { ErrorSnackbar };
