import * as React from 'react';
import { useEffect } from 'react';
// import { Breadcrumbs } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import BinaryList from '../components/Binary/List';
import BinaryForm from '../components/Binary/Form';

export default function Binary() {
  // WEBPAGE_______________________
  useEffect(() => {
  }, []);

  return (
    <div style={{ height: 400, width: '100%' }}>
      {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      <Link to="/binaries">Binaries</Link>
    </Breadcrumbs> */}
      <h1>Binaries</h1>
      <BinaryList />
      <BinaryForm />
    </div>
  );
}
