import { useState, useCallback } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';

const defaultOpts = {
  title: 'Title',
  description: 'Description',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  onConfirm: () => console.warn('[ConfirmDialog] onConfirm in not defined'),
};

const useConfirmationDialog = () => {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState(defaultOpts);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const askConfirmation = useCallback((opts) => {
    setOptions(Object.assign(defaultOpts, { ...opts }));
    setOpen(true);
  }, []);

  return {
    isOpen,
    options,
    onClose,
    askConfirmation,
  };
};

export { useConfirmationDialog, ConfirmationDialog };
