import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button, CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  dialogAction: {
    padding: theme.spacing(2, 3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ConfirmationDialog = (props) => {
  ConfirmationDialog.propTypes = {
    options: PropTypes.shape({
      onConfirm: PropTypes.func.isRequired,
      description: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      cancellationText: PropTypes.string.isRequired,
      confirmationText: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  const { options, onClose } = props;
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    await options.onConfirm();
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {options.title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {options.description}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.dialogAction}>
        <Button
          color="default"
          variant="outlined"
          onClick={handleClose}
          autoFocus
        >
          {options.cancellationText}
        </Button>
        <div className={classes.wrapper}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleConfirm}
            disabled={isLoading}
          >
            {options.confirmationText}
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
