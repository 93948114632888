import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const CustomBreadcrumbs = ({ crumbs }) => {
  CustomBreadcrumbs.propTypes = {
    crumbs: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired, path: PropTypes.string.isRequired,
    })).isRequired,
  };
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
        {/* Link back to any previous steps of the breadcrumb. */}
        {crumbs.map(({ name, path }, index) => (index + 1 === crumbs.length ? (
          <Typography color="textPrimary" key={name}>{name}</Typography>
        ) : (
          <Link color="inherit" to={path} key={name}>
            {name}
          </Link>
        )))}
      </Breadcrumbs>
    </div>
  );
};
export default CustomBreadcrumbs;
