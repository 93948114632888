import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import Layout from './components/Layout';
import { FeedbackProvider } from './components/Utils/FeedbackTools';

function App() {
  return (
    <AmplifyAuthenticator>
      <AmplifySignOut />
      <FeedbackProvider>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </FeedbackProvider>
    </AmplifyAuthenticator>
  );
}

export default App;
