/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState,
} from 'react';
import {
  Button,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import PublishIcon from '@material-ui/icons/Publish';

import GraphqlService from '../../service/graphqlService';

export default function BinaryForm() {
  // STATE________________________________
  const [submitting, setSubmitting] = useState(false);

  // FORM_________________________________
  // eslint-disable-next-line no-unused-vars

  const {
    register, handleSubmit, reset,
  } = useForm({});

  async function save(binary) {
    setSubmitting(true);
    const response = await GraphqlService.createBinary(binary);
    alert(`file= ${JSON.stringify(response)}`);
    setSubmitting(false);
    reset();
  }

  const onSubmit = (data) => {
    save(
      data.binary[0],
    );
  };

  // WEBPAGE______________________________

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <input
        {...register('binary')}
        label="File"
        type="file"
        required
      />
      <br />

      <Button
        type="submit"
        variant="contained"
        endIcon={<PublishIcon />}
        color="primary"
        disabled={submitting}
      >
        Upload
      </Button>
      <Button
        type="reset"
        variant="contained"
        color="default"
        disabled={submitting}
      >
        Reset
      </Button>
    </form>
  );
}
