/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState, useEffect,
} from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PublishIcon from '@material-ui/icons/Publish';

import GraphqlService from '../../service/graphqlService';
import { iotDevBucketName, iotStagBucketName, target } from '../../config/awsExports';

const columns = [
  { field: 'id', headerName: 'Key', width: 500 },
  { field: 'lastModified', headerName: 'Last Modified', width: 250 },
  { field: 'eTag', headerName: 'eTag', width: 130 },
  { field: 'size', headerName: 'Size', width: 90 },
  { field: 'storageClass', headerName: 'Storage Class', width: 130 },
];

export default function AssetCreate() {
  // STATE________________________________
  const [submitting, setSubmitting] = useState(false);
  const [binaries, setBinaries] = useState([]);
  const [assetTypes, setAssetTypes] = useState(
    [],
  );
  const history = useHistory();

  // DATA RETRIEVAL_______________________

  async function fetchBinaries() {
    const binariesList = await GraphqlService.getBinaries();
    const tempBinaries = [];
    if (binariesList.contents) {
      binariesList.contents.forEach((o) => {
        o.id = o.key;
        tempBinaries.push(o);
      });
    }
    setBinaries(tempBinaries);
  }

  async function fetchAssetTypes() {
    setSubmitting(true);
    const assetTypesList = await GraphqlService.getAssetTypes();
    const tempAssetTypes = [];
    assetTypesList.forEach((o) => {
      tempAssetTypes.push(o.name);
    });
    setAssetTypes(tempAssetTypes);
    setSubmitting(false);
  }

  // FORM_________________________________
  // eslint-disable-next-line no-unused-vars
  const [defaultValues, setDefaultValues] = useState({
    region: 'eu-west-1',
    timestampToApply: '99:99',
  });

  const {
    register, handleSubmit, setValue, formState: { errors }, control,
  } = useForm({
    defaultValues,
  });

  async function save(type, chunks, region, version, timestampToApply) {
    setSubmitting(true);
    const creationResponse = await GraphqlService.createAsset(
      type, [chunks], region, version, timestampToApply,
    );
    // eslint-disable-next-line no-underscore-dangle
    if (creationResponse.__typename === 'CreateAssetSuccess') {
      alert(`File ID= ${creationResponse.fileID}`);
      setSubmitting(false);
      history.goBack();
    } else {
      alert(`Error= ${creationResponse.statusCode} ${creationResponse.message}`);
      setSubmitting(false);
    }
  }

  const onSubmit = (data) => {
    const iotBucketName = target === 'DEV' ? iotDevBucketName : iotStagBucketName;
    save(
      assetTypes[data.type],
      `s3://${iotBucketName}/${data.binaryID}`,
      data.region,
      Number(data.version),
      data.timestampToApply,
    );
  };

  // WEBPAGE______________________________

  useEffect(() => {
    fetchBinaries();
    fetchAssetTypes();
  }, []);

  return (
    <div>
      <h1>
        Create new asset
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={assetTypes}
              getOptionLabel={(option) => option}
              style={{ width: '90%' }}
              renderInput={(params) => <TextField {...params} required label="Type" />}
              disabled={submitting}
              autoSelect
            />
          )}
        />

        <TextField
          {...register('version')}
          type="number"
          label="Version"
          defaultValue=""
          required
          style={{ width: '90%' }}
          disabled={submitting}
        />
        <br />

        <TextField
          {...register('region')}
          label="Region"
          defaultValue={defaultValues.region}
          required
          style={{ width: '90%' }}
          disabled={submitting}
        />
        <br />

        <TextField
          {...register('timestampToApply')}
          label="Timestamp To Apply"
          defaultValue={defaultValues.timestampToApply}
          required
          style={{ width: '90%' }}
          disabled={submitting}
        />
        <br />

        <div style={{ height: 400, width: '90%' }}>
          <p>Binary</p>
          <DataGrid
            rows={binaries}
            columns={columns}
            pageSize={10}
            disableMultipleSelection
            onSelectionChange={(newSelection) => setValue('binaryID', newSelection.rowIds[0])}
            {...register('binaryID', {
              required: {
                value: true,
                message: '*You need to select an asset',
              },
            })}
          />
        </div>
        <br />
        <br />
        <p style={{ color: 'red' }}>{errors?.fileID?.message}</p>

        <Button
          type="submit"
          variant="contained"
          endIcon={<PublishIcon />}
          color="primary"
          disabled={submitting}
        >
          Create
        </Button>
        <Button
          type="reset"
          variant="contained"
          color="default"
          disabled={submitting}
        >
          Reset
        </Button>
        <Button
          onClick={() => history.goBack()}
          variant="contained"
          color="default"
          disabled={submitting}
        >
          Cancel
        </Button>
      </form>
    </div>
  );
}
