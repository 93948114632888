/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // eslint-disable-next-line no-unused-vars
  Legend,
  Cell,
} from 'recharts';
import {
  // eslint-disable-next-line no-unused-vars
  WiDaySunny, WiRain, WiStormShowers, WiDayFog, WiMeteor,
} from 'react-icons/wi';
import { IconContext } from 'react-icons';
import GraphqlService from '../../../service/graphqlService';
import { useStyles } from '../../../config/styles';
import { useFeedbackTools } from '../../Utils/FeedbackTools';
// import { func } from 'prop-types';

const getRandomString = () => Math.random().toString(36).substr(2, 10);

function roundPercentage(percentage) {
  return Math.round(parseInt(percentage, 10) * 100) / 100;
}

const green = '#58C0A1';
const yellow = '#E2dd53';
const red = '#F16D64';
const blue = '#5597DE';

/* function getValue(severity) {
  if (severity === 'POOR') { return 100; }
  if (severity === 'IMPROVABLE') { return 70; }
  if (severity === 'GOOD') { return 50; }
  return 50;
} */
function getColor(severity) {
  if (severity === 'POOR') { return red; }
  if (severity === 'IMPROVABLE') { return yellow; }
  if (severity === 'GOOD') { return green; }
  return green;
}
/* unction getStatusFromValue(value) {
  if (value === 100) { return 'POOR'; }
  if (value === 70) { return 'IMPROVABLE'; }
  if (value === 50) { return 'GOOD'; }
  return 'GOOD';
} */
// eslint-disable-next-line no-unused-vars
function getRealSeverity(severity) {
  if (severity === 'POOR') { return 'POOR'; }
  if (severity === 'IMPROVABLE') { return 'IMPROVABLE'; }
  if (severity === 'GOOD') { return 'GOOD'; }
  return 'NA';
}

export default function HealthServiceDisplay() {
  const classes = useStyles();
  // STATE________________________________
  const { serviceId } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [servicesHealth, setServicesHealth] = useState([]);
  const [failureDetails, setFailureDetails] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [popoverContent, setPopoverContent] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [popoverContentArray, setPopoverContentArray] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [failedTests, setFailedTests] = useState([]);
  const [serviceHealthHistory, setServiceHealthHistory] = useState([]);
  const [health, setHealth] = useState('');
  const [healthPercentage, setHealthPercentage] = useState('');
  const [healthUpdatedAt, setHealthUpdatedAt] = useState('');

  const [overallLoader, setOverallLoader] = useState(false);
  const [historyLoader, setHistoryLoader] = useState(false);

  // DATA RETRIEVAL_______________________
  const { showError } = useFeedbackTools();

  function trasformFailureData(failures) {
    // eslint-disable-next-line prefer-const
    let data = [];
    failures.forEach((f) => {
      let item = {};
      if (f.type) {
        item = {
          name: f.name,
          criticality: f.criticality,
          type: f.type,
          machine_model_id: null,
          link_details: null,
        };
      } else {
        item = {
          name: f.title,
          criticality: f.criticality,
          type: 'e2eTest',
          machine_model_id: f.brand,
          link_details: f.logs,
        };
      }
      data.push(item);
    });
    return data;
  }

  async function fetchServicesHealth() {
    try {
      const servicesHealthResponse = await GraphqlService.getServiceHealth(serviceId);
      if (servicesHealthResponse.getServicesHealth.list.length > 0) {
        // eslint-disable-next-line max-len
        setServicesHealth(servicesHealthResponse.getServicesHealth.list);
        setHealth(servicesHealthResponse.getServicesHealth.list[0].health);
        setHealthPercentage(servicesHealthResponse.getServicesHealth.list[0].healthPercentage);
        setHealthUpdatedAt(servicesHealthResponse.getServicesHealth.list[0].healthUpdatedAt);
        let failures = [];
        if (servicesHealthResponse.getServicesHealth.list[0].failureDetails) {
          failures = servicesHealthResponse.getServicesHealth.list[0].failureDetails.filter((x) => x.type !== 'e2eTest');
        }
        // eslint-disable-next-line max-len
        if (servicesHealthResponse.getServicesHealth.list[0] && servicesHealthResponse.getServicesHealth.list[0].links) {
          const failedTestsResponse = await GraphqlService.getFailedTests(servicesHealthResponse.getServicesHealth.list[0].links.tests);
          if (failedTestsResponse.getTests.list) {
            failures = [...failures, ...failedTestsResponse.getTests.list];
          }
        }
        setFailureDetails(trasformFailureData(failures));
        // eslint-disable-next-line max-len
        // const failedTestsResponse = await GraphqlService.getFailedTests('/tests/executions/FOTA?date=2021-07-14');
      }
      setOverallLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${JSON.stringify(error, null, 4)}`);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function fetchTestDetails(filePath) {
    let logsResponse;
    try {
      logsResponse = await GraphqlService.getTestDetails(filePath);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${error}`);
    }
    return logsResponse;
  }

  async function handleClick(event, value) {
    try {
      setAnchorEl(event.currentTarget);
      const testDetailsResponse = await fetchTestDetails(value);
      setPopoverContentArray(testDetailsResponse.getTestDetail.split('\n'));
      setPopoverContent(testDetailsResponse.getTestDetail.replaceAll('\n', '<br/>'));
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${error}`);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  async function fetchServiceHealthHistory() {
    try {
      const serviceHealthHistoryResponse = await GraphqlService.getServiceHealthHistory(serviceId);
      if (serviceHealthHistoryResponse.getPlatformHealthHistory.list.length > 0) {
        // eslint-disable-next-line no-param-reassign
        serviceHealthHistoryResponse.getPlatformHealthHistory.list[0].History.list.forEach((o) => { o.value = roundPercentage(o.healthPercentage); });
        setServiceHealthHistory(serviceHealthHistoryResponse.getPlatformHealthHistory.list[0].History.list);
      }
      setHistoryLoader(true);
    } catch (error) {
      showError('Internal Server Error');
      console.log(`ERROR: ${error}`);
    }
  }

  // WEBPAGE______________________________

  useEffect(() => {
    fetchServicesHealth();
    fetchServiceHealthHistory();
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: '#b8b4b8', padding: 5, color: '#FFFFFF', textAlign: 'center',
          }}
        >
          <p className="label"><b>{`${label}`}</b></p>
          <p className="intro"><b>{roundPercentage(payload[0].value)}</b></p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <h1>
        {serviceId}
        {' '}
        Service Health
      </h1>
      <Paper
        elevation={2}
        style={{
          padding: 16,
        }}
      >
        <h2>Overall</h2>
        {overallLoader ? servicesHealth.length > 0 ? (
          <div
            width={1200}
            height={400}
            align="center"
            text-align="center"
            className={classes.weatherReport}
          >
            <Grid container>
              <Grid item xs={4}>
                {(function () {
                  if (health === 'POOR') {
                    return (
                      <IconContext.Provider value={{ color: blue, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiStormShowers /></div>
                      </IconContext.Provider>

                    );
                  }
                  if (health === 'IMPROVABLE') {
                    return (
                      <IconContext.Provider value={{ color: yellow, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiDayFog /></div>
                      </IconContext.Provider>
                    );
                  }
                  if (health === 'GOOD') {
                    return (
                      <IconContext.Provider value={{ color: green, className: 'global-class-name', size: '10em' }}>
                        {' '}
                        <div><WiDaySunny /></div>
                      </IconContext.Provider>
                    );
                  }

                  return (
                    <IconContext.Provider value={{ color: green, className: 'global-class-name', size: '10em' }}>
                      {' '}
                      <div><WiDaySunny /></div>
                    </IconContext.Provider>
                  );
                }())}
              </Grid>
              <Grid item xs={4}>
                <Box fontSize={40}>
                  {(healthPercentage) ? `${getRealSeverity(health)} (${roundPercentage(healthPercentage)}%)` : getRealSeverity(health)}
                </Box>
              </Grid>
              <Grid item align="left" text-align="left" xs={4}>
                <Box>
                  Updated at:
                  {' '}
                  {healthUpdatedAt}
                </Box>
              </Grid>
            </Grid>
          </div>
        )
          : (
            <div>
              <p>No data.</p>
            </div>
          )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
        <Box m={6} />
        <Divider />
        <h2>
          {serviceId}
          {' '}
          {' '}
          Health History
        </h2>
        {historyLoader ? (
          <div>
            {serviceHealthHistory.length > 0 ? (
              <div>
                <Box m={6} />
                <Grid container>
                  <Grid item align="center" text-align="center" xs={12}>
                    <BarChart
                      width={1200}
                      height={100}
                      data={serviceHealthHistory}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis domain={[0, 100]} />
                      <Tooltip content={<CustomTooltip />} />
                      <Bar dataKey="value" barSize={60} label={{ position: 'top' }}>
                        {serviceHealthHistory.map((entry) => (
                          <Cell fill={getColor(entry.health)} key={`entry-${new Date().getTime()}`} />
                        ))}
                      </Bar>
                    </BarChart>
                  </Grid>
                </Grid>
              </div>
            )
              : (
                <div>
                  <p>No data.</p>
                </div>
              )}
            <Box m={10} />
            <h2>Failure Details</h2>
            {failureDetails.length > 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableHeadCell}>Name</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Criticality</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Type</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Machine Model ID</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {failureDetails.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{parseFloat(row.criticality).toFixed(2)}</TableCell>
                        <TableCell align="right">{row.type}</TableCell>
                        <TableCell align="right">{row.machine_model_id ? row.machine_model_id : '/'}</TableCell>
                        <TableCell align="right">
                          {row.link_details ? (
                            <Button key={row.link_details} aria-describedby={id} value={row.link_details} variant="contained" color="primary" onClick={(e) => handleClick(e, row.link_details)}>
                              See details
                            </Button>
                          )
                            : (
                              '/'
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
              : (
                <div>
                  <p>No data.</p>
                </div>
              )}
            {
              /*
              <div>
                <Box m={6} />
                <Divider />
                <h2>Failing Tests</h2>
                {failedTests.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={classes.tableHeadCell}>Machine Model ID</TableCell>
                          <TableCell className={classes.tableHeadCell} align="right">Severity</TableCell>
                          <TableCell className={classes.tableHeadCell} align="right">Title</TableCell>
                          <TableCell className={classes.tableHeadCell} align="right">Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {failedTests.map((row) => (
                          <TableRow key={row.title}>
                            <TableCell component="th" scope="row">
                              {row.brand}
                            </TableCell>
                            <TableCell align="right">{row.category ? getRealSeverity(row.category) : 'NA'}</TableCell>
                            <TableCell align="right">{row.title}</TableCell>
                            <TableCell align="right">
                              <Button key={row.logs} aria-describedby={id} value={row.logs} variant="contained" color="primary" onClick={(e) => handleClick(e)}>
                                See details
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
                  : (
                    <div>
                      <p>No data.</p>
                    </div>
                  )}
              </div>
              */
            }
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2}>
                <Box display="flex" flexDirection="row-reverse">
                  <Button variant="contained" color="primary" onClick={handleClose}>Close</Button>
                </Box>
                {popoverContentArray.map((row) => (
                  <Typography key={getRandomString()} gutterBottom className={classes.typography}>{row}</Typography>
                ))}
              </Box>
            </Popover>
            <Box m={6} />
            <h2>
              {serviceId}
              {' '}
              Health History Details
            </h2>
            {serviceHealthHistory.length > 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableHeadCell} align="right">Date</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">E2E Tests Updated At</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Health Status</TableCell>
                      <TableCell className={classes.tableHeadCell} align="right">Health Status Updated At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {serviceHealthHistory.map((row) => (
                      <TableRow key={row.date}>
                        <TableCell align="right">{row.date}</TableCell>
                        <TableCell align="right">{row.e2eTestsUpdatedAt}</TableCell>
                        <TableCell align="right">
                          {(row.healthPercentage) ? `${getRealSeverity(row.health)} (${roundPercentage(row.healthPercentage)}%)` : getRealSeverity(row.health)}
                        </TableCell>
                        <TableCell align="right">{row.healthUpdatedAt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
              : (
                <div>
                  <p>No data.</p>
                </div>
              )}
            <Divider />
          </div>
        )
          : (
            <div className={classes.boxLoader}>
              <CircularProgress />
            </div>
          )}
      </Paper>
    </div>
  );
}
