/* eslint-disable indent */
import {
  React,
  useEffect,
} from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from '@material-ui/core';
import { Home } from '@material-ui/icons';
import StopIcon from '@material-ui/icons/Stop';
import { makeStyles, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import InputBase from '@material-ui/core/InputBase';
// eslint-disable-next-line no-unused-vars
import SearchIcon from '@material-ui/icons/Search';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Hub } from 'aws-amplify';
import Profile from './Profile';

import {
  red,
  green,
  yellow,
} from './Utils/Legend';

import { useFeedbackTools } from './Utils/FeedbackTools';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkText: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: 'white',
  },
  // this group of buttons will be aligned to the right side
  toolbarButtons: {
    marginLeft: 'auto',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  legendButtonHeader: {
    marginRight: theme.spacing(3),
    textTransform: 'none',
  },
}));

function Legends() {
  const severetyList = [
    { severity: 'POOR', color: red, description: 'Health of the platform or of the specified services is quite low. It needs urgent actions' },
    {
      severity: 'IMPROVABLE',
      color: yellow,
      description: 'Health of the platform or of the specified services can not be determined. A deeper investigation is needed',
    },
    {
      severity: 'GOOD',
      color: green,
      description: 'Health of the platform or of the specified services is high. Only secondary problems are detected',
    },
  ];

  return (
    <List>
      {
        severetyList.map((item) => (
          <ListItem key={item.severity}>
            <ListItemIcon>
              <StopIcon style={{ color: item.color }} />
            </ListItemIcon>
            <ListItemText
              primary={item.severity}
              secondary={item.description}
            />
          </ListItem>
        ))
      }
    </List>
  );
}

const navLinks = [
/*   { title: 'about us', path: '/about-us' },
  { title: 'product', path: '/product' },
  { title: 'blog', path: '/blog' },
  { title: 'contact', path: '/contact' },
  { title: 'faq', path: '/faq' },
 */];

function NavigationLink(props) {
  const classes = useStyles();
  const { path, title } = props;

  return (
    <a href={path} key={title} className={classes.linkText}>
      <ListItem button>
        <ListItemText primary={title} />
      </ListItem>
    </a>
  );
}
NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

function Header(props) {
  const { viewInfoDialog } = useFeedbackTools();
  const classes = useStyles();
  const { user, setUser } = props;

  const handleClickOpenLegend = () => {
    viewInfoDialog({
      title: 'Health Status Categories',
      content: <Legends />,
    });
  };

  useEffect(() => {
    const updateUser = async (data) => {
      // console.log(data.payload.event);
      if (data.payload.event === 'signIn') {
        handleClickOpenLegend();
      }
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    return () => Hub.remove('auth', updateUser); // cleanup
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/* This is the home button on the left */}
        <IconButton edge="start" color="inherit" aria-label="home">
          <Home fontSize="large" />
        </IconButton>
        <Typography variant="h6" noWrap>
          IoT Health
        </Typography>
        {/*         <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
 */}
        {' '}
        <div className={classes.grow} />

        {/* These are the links to the different sections  */}
        <div className={classes.toolbarButtons}>
          <List
            component="nav"
            aria-labelledby="main navigation"
            className={classes.navDisplayFlex}
          >
            {navLinks.map(({ title, path }) => (
              <NavigationLink title={title} path={path} key={title} />
            ))}
          </List>
        </div>
        <Button
          variant="contained"
          className={classes.legendButtonHeader}
          endIcon={<HelpOutlineIcon />}
          onClick={handleClickOpenLegend}
        >
          What is Health Status?
        </Button>
        <Profile user={user} setUser={setUser} />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
};

Header.defaultProps = {
  user: {
    username: '',
  },
};

export default Header;
