/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  severity: {
    fontSize: 40,
  },
  table: {
    minWidth: 650,
  },
  weatherReport: {
  },
  tableHead: {
    backgroundColor: '#F5F5F5',
  },
  tableHeadCell: {
    fontWeight: 'bolder',
  },
  boxLoader: {
    display: 'flex',
    justifyContent: 'center',
    margin: 20,
  },
}));
