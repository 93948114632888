import React from 'react';
import {
  IconButton,
  Typography,
  Dialog,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

const dialogTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const dialogContentStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

function DialogTitle(props) {
  const classes = dialogTitleStyles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

DialogTitle.propTypes = {
  children: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function DialogContent(props) {
  const classes = dialogContentStyles();
  const { children } = props;
  return (
    <MuiDialogContent className={classes.root}>
      {children}
    </MuiDialogContent>
  );
}
DialogContent.propTypes = {
  children: PropTypes.element.isRequired,
};

const InfoDialog = (props) => {
  InfoDialog.propTypes = {
    options: PropTypes.shape({
      content: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
  };

  const { options, onClose } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {options.title}
      </DialogTitle>
      <DialogContent dividers>
        {options.content}
      </DialogContent>
    </Dialog>
  );
};

export { InfoDialog };
