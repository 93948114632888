// QUERIES__________________________

export const listAssets = /* GraphQL */ `
  query ListAssets  {
    listAssets {
      ... on ListAssetsSuccess {
        __typename
        list {
          type
          fileID
          dateTime
          chunks
          metaData {
            timestampToApply
            version
          }
          content
          hashList
          hash
          manufacturerSignature
          softwareRequirements
          hardwareRequirements
          region
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const listDeployments = /* GraphQL */ `
  query ListDeployments  {
    listDeployments {
      ... on ListDeploymentsSuccess {
        __typename
        list {
          deploymentID
          dateTime
          actor
          file {
            type
            fileID
            dateTime
            chunks
            content
            hashList
            hash
            manufacturerSignature
            softwareRequirements
            hardwareRequirements
            region
          }
          target
          exclude
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const listBinaries = /* GraphQL */ `
  query ListBinaries  {
    listBinaries {
      ... on ListBinariesSuccess {
        __typename
        commonPrefixes {
          prefix
        }
        contents {
          key
          lastModified
          eTag
          size
          storageClass
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const getBinary = /* GraphQL */ `
  query GetBinary ($key: String!) {
    getBinary (key: $key) {
      ... on GetBinarySuccess {
        __typename
        key
        lastModified
        eTag
        size
        storageClass
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const getAssetTypes = /* GraphQL */ `
  query GetAssetTypes {
    getAssetTypes {
      ... on GetAssetTypesSuccess {
        __typename
        assetTypes {
          name
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

// MUTATIONS_________________________

export const createAsset = /* GraphQL */ `
  mutation CreateAsset(
      $type: String!,
      $chunks: [String]!,
      $region: String!,
      $version: Int!,
      $timestampToApply: String,
      $manufacturerSignature: String,
      $softwareRequirements: String,
      $hardwareRequirements: String,
      $fileID: String,
      ) {
    createAsset
    (
      createAssetInput:
      {
        type: $type,
        fileID: $fileID,
        chunks: $chunks
        region: $region,
        metaData: {
          version: $version,
          timestampToApply: $timestampToApply,
        },
        manufacturerSignature: $manufacturerSignature,
        softwareRequirements: $softwareRequirements,
        hardwareRequirements: $hardwareRequirements,
      }) {
        ... on CreateAssetSuccess {
          __typename
          fileID
        }
        ... on BadRequest {
          __typename
          message
          statusCode
        }
        ... on Forbidden {
          __typename
          message
          statusCode
        }
        ... on InternalServerError {
          __typename
          message
          statusCode
        }
    }
  }
`;

export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset($fileID: String!) {
    deleteAsset(
      fileID: $fileID
      ) {
        ... on DeleteAssetSuccess {
          response
        }
        ... on BadRequest {
          __typename
          message
          statusCode
        }
      }
  }
`;

export const createDeployment = /* GraphQL */ `
  mutation CreateDeployment($fileID: String!, $target: String!) {
    createDeployment
    (
      createDeploymentInput: {
        target: $target,
        fileID: $fileID
        }
      ) {
        ... on CreateDeploymentSuccess {
          __typename
          deploymentID
        }
        ... on BadRequest {
          __typename
          message
          statusCode
        }
    }
  }
`;

export const deleteDeployment = /* GraphQL */ `
  mutation DeleteDeployment($deploymentID: String!) {
      deleteDeployment(
        deploymentID: $deploymentID
        ) {
          ... on DeleteDeploymentSuccess {
            response
          }
          ... on BadRequest {
            __typename
            message
            statusCode
          }
        }
  }
`;

export const createBinary = /* GraphQL */ `
  mutation CreateBinary($filekey: String!) {
    createBinary
    (
        key: $filekey
      ) {
        ... on CreateBinarySuccess {
          __typename
          key
          lastModified
          eTag
          size
          storageClass
        }
        ... on BadRequest {
          __typename
          message
          statusCode
        }
    }
  }
`;

export const deleteBinary = /* GraphQL */ `
  mutation DeleteBinary($filekey: String!) {
    deleteBinary(
        key: $filekey
    ) {
        key
    }
  }
`;

export const getHealth = /* GraphQL */ `
  query GetHealth {
    getHealth
  }
`;

export const getOverallHealth = `
  query GetOverallHealth {
    getOverallHealth {
      ... on OverallHealth {
        health
        healthPercentage
        date
        e2eTestsUpdatedAt
        healthUpdatedAt
        lastNonGoodHealth {
          healthUpdatedAt
          health
          healthPercentage
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const getServicesHealth = `
  query GetServicesHealth {
    getServicesHealth(serviceHealthInput: {params: ""}) {
      ... on ListServiceHealth {
        __typename
        list {
          links {
            alarms
            tests
          }
          service
          health
          healthPercentage
          healthUpdatedAt
          failureDetails {
            name
            criticality
            type
          }
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const getServiceHealth = `
  query GetServicesHealth($serviceId: [String]) {
    getServicesHealth(serviceHealthInput: {services: $serviceId}) {
      ... on ListServiceHealth {
        __typename
        list {
          links {
            alarms
            tests
          }
          service
          health
          healthPercentage
          healthUpdatedAt
          failureDetails {
            name
            criticality
            type
          }
        }
      }
      ... on BadRequest {
        __typename
        message
        statusCode
      }
    }
  }
`;

export const getHealthHistory = `
  query GetHealthHistory {
    getHealthHistory {
      ... on PlatformHealthHistory {
        __typename
        list {
          date
          e2eTestsUpdatedAt
          health
          healthPercentage
          healthUpdatedAt
        }
      }
    }
  }
`;

export const getBrandsHealth = `
  query GetBrandsHealth {
    getBrandHealth(serviceHealthInput: {brands: ["1", "2","3","5","6","7","9","10","11","13","14","18","19","23","24","28"]}) {
      ... on ListBrandHealth {
        __typename
        list {
          Brand
          Overall {
            date
            e2eTestsUpdatedAt
            health
            healthPercentage
            healthUpdatedAt
            lastNonGoodHealth {
              healthUpdatedAt
              health
              healthPercentage
            }
          }
          Service {
            list {
              service
              health
              healthPercentage
              healthUpdatedAt
            }
          }
        }
      }
    }
  }
`;

export const getBrandHealth = `
  query GetBrandHealth($brandId: [String]) {
    getBrandHealth(serviceHealthInput: {brands: $brandId}) {
      ... on ListBrandHealth {
        __typename
        list {
          Brand
          Service {
            list {
              links {
                alarms
                tests
              }
              service
              health
              healthPercentage
              healthUpdatedAt
              failureDetails {
                name
                criticality
                type
              }
            }
          }
          Overall {
            date
            e2eTestsUpdatedAt
            health
            healthPercentage
            healthUpdatedAt
            lastNonGoodHealth {
              healthUpdatedAt
              health
              healthPercentage
            }
          }
        }
      }
    }
  }
`;

export const getFailedTests = `
  query GetFailedTests($testId: String) {
    getTests(testId: $testId) {
      ... on ListTestResult {
        __typename
        list {
          brand
          duration
          category
          e2e_execution_id
          logs
          started_at
          suite
          test_id
          title
        }
      }
    }
  }
`;

export const getServiceHealthHistory = `
  query GetServiceHealthHistory($serviceId: [String]) {
    getPlatformHealthHistory(serviceHealthInput: {services: $serviceId}) {
      ... on ServiceHealthHistoryResult {
        __typename
        list {
          History {
            list {
              date
              e2eTestsUpdatedAt
              health
              healthPercentage
              healthUpdatedAt
            }
          }
          Service
        }
      }
    }
  }
`;

export const getTestDetails = `
  query GetTestDetails($filePath: String) {
    getTestDetail(filePath: $filePath)
  }
`;

export const getBrandHealthHistory = `
query GetBrandHealthHistory($brandId: [String]) {
  getPlatformHealthHistory(serviceHealthInput: {brands: $brandId}) {
    ... on BrandsHealthHistoryResult {
      __typename
      list {
        Brand
        PlatformHealthHistory {
          list {
            date
            e2eTestsUpdatedAt
            health
            healthPercentage
            healthUpdatedAt
          }
        }
      }
    }
  }
}
  `;
