/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState, useEffect,
} from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';

import GraphqlService from '../../service/graphqlService';

export default function AssetEdit() {
  // STATE________________________________
  const [submitting, setSubmitting] = useState(false);
  const { assetId } = useParams();
  const history = useHistory();
  const defaultValues = {
    type: '',
    region: '',
    version: '',
    timestampToApply: '',
    binary: '',
  };
  const {
    register, handleSubmit, reset, control,
  } = useForm({ defaultValues });

  // DATA RETRIEVAL_______________________

  async function fetchAssets() {
    setSubmitting(true);
    const assetsList = await GraphqlService.getAssets();
    const asset = assetsList.filter((filteredAsset) => filteredAsset.fileID === assetId)[0];
    if (asset) {
      reset({
        type: asset.type,
        region: asset.region,
        version: asset.metaData?.version ? asset.metaData.version : 0,
        timestampToApply: asset.metaData?.timestampToApply ? asset.metaData.timestampToApply : '99:99',
        binary: asset.chunks[0],
      });
    }
    setSubmitting(false);
  }

  // FORM_________________________________

  async function edit(type, chunks, region, version, timestampToApply) {
    setSubmitting(true);
    await GraphqlService.deleteAsset(assetId);
    const creationResponse = await GraphqlService.createAsset(
      type, [chunks], region, version, timestampToApply,
    );
    // eslint-disable-next-line no-underscore-dangle
    if (creationResponse.__typename === 'CreateAssetSuccess') {
      alert(`File ID= ${creationResponse.fileID}`);
      history.push(`/assets/${creationResponse.fileID}`);
    } else {
      alert(`Error= ${creationResponse.statusCode} ${creationResponse.message}`);
    }
    setSubmitting(false);
  }

  const onSubmit = (data) => {
    edit(
      data.type,
      data.binary,
      data.region,
      data.version,
      data.timestampToApply,
    );
  };

  // WEBPAGE______________________________

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <div>
      <h1>
        Edit asset
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>

        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <TextField
              {...field}
              label="Type"
              required
              style={{ width: '90%' }}
              disabled={submitting}
            />
          )}
        />

        <Controller
          control={control}
          name="version"
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label="Version"
              required
              style={{ width: '90%' }}
              disabled={submitting}
            />
          )}
        />
        <br />

        <Controller
          control={control}
          name="region"
          render={({ field }) => (
            <TextField
              {...field}
              label="Region"
              required
              style={{ width: '90%' }}
              disabled={submitting}
            />
          )}
        />
        <br />

        <Controller
          control={control}
          name="timestampToApply"
          render={({ field }) => (
            <TextField
              {...field}
              label="Timestamp To Apply"
              required
              style={{ width: '90%' }}
              disabled={submitting}
            />
          )}
        />
        <br />

        <Controller
          control={control}
          name="binary"
          render={({ field }) => (
            <TextField
              {...field}
              label="Binary"
              required
              style={{ width: '90%' }}
              disabled={submitting}
            />
          )}
        />
        <br />

        <br />
        <br />

        <Button
          type="submit"
          variant="contained"
          endIcon={<EditIcon />}
          color="primary"
          disabled={submitting}
        >
          Submit
        </Button>
        <Button
          onClick={() => history.goBack()}
          variant="contained"
          color="default"
          disabled={submitting}
        >
          Cancel
        </Button>
      </form>
    </div>
  );
}
