import { useState, useCallback } from 'react';
import { ErrorSnackbar } from './ErrorSnackbar';

const useErrorSnackbar = () => {
  const [errorMsg, setErrorMsg] = useState();

  const showError = useCallback((e) => {
    const unknown = 'Unknown Error';
    let msg = unknown;

    if (e && e.response) {
      const { response } = e;
      const statusMessage = response.statusText || unknown;
      msg = response.data && response.data.message ? response.data.message : statusMessage;
    } else if (e && e.message) {
      msg = e.message;
    } else if (e && typeof e === 'string') {
      msg = e;
    }

    setErrorMsg(msg);
  }, []);

  const handleClose = useCallback(() => {
    setErrorMsg();
  }, []);

  return {
    errorMsg,
    handleClose,
    showError,
  };
};

export { useErrorSnackbar, ErrorSnackbar };
