/* eslint-disable react/jsx-props-no-spreading */
import {
  React, useState, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useForm } from 'react-hook-form';
import PublishIcon from '@material-ui/icons/Publish';

import GraphqlService from '../../service/graphqlService';

const assetColumns = [
  { field: 'id', headerName: 'ID', width: 550 },
  { field: 'type', headerName: 'Type', width: 150 },
  { field: 'dateTime', headerName: 'DateTime', width: 220 },
  { field: 'region', headerName: 'Region', width: 130 },
];

export default function DeploymentCreate() {
  // STATE________________________________
  const [assets, setAssets] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  async function fetchAssets() {
    const assetsResponse = await GraphqlService.getAssets();
    assetsResponse
      .filter((o) => o.fileID)
      .forEach((o) => {
        // eslint-disable-next-line no-param-reassign
        o.id = o.fileID;
      });
    setAssets(assetsResponse);
  }

  // FORM_________________________________
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm();

  async function save(fileID, target) {
    setSubmitting(true);
    const deployResponse = await GraphqlService.createDeployment(fileID, target);
    alert(`DeploymentID: ${deployResponse}`);
    history.goBack();
    setSubmitting(false);
  }

  const onSubmit = (data) => {
    save(data.fileID, data.target);
  };

  // WEBPAGE______________________________

  useEffect(() => {
    fetchAssets();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <h1>
        Add new deployment
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>

        <TextField
          {...register('target')}
          label="Target"
          required
          style={{ width: '90%' }}
          defaultValue=""
        />
        <br />

        <div style={{ height: 400, width: '90%' }}>
          <DataGrid
            rows={assets}
            columns={assetColumns}
            pageSize={5}
            disableMultipleSelection
            onSelectionChange={(newSelection) => setValue('fileID', newSelection.rowIds[0])}
            {...register('fileID', {
              required: {
                value: true,
                message: '*You need to select an asset',
              },
            })}
          />
        </div>
        <p style={{ color: 'red' }}>{errors?.fileID?.message}</p>

        <Button
          type="submit"
          variant="contained"
          endIcon={<PublishIcon />}
          color="primary"
          disabled={submitting}
        >
          Deploy
        </Button>
        <Button
          type="reset"
          variant="contained"
          color="default"
          disabled={submitting}
        >
          Reset
        </Button>
      </form>
    </div>
  );
}
